import { useEffect } from "react"
import { navigate } from "gatsby"

const Index = () => {
  useEffect(() => {
    navigate("/exploring")
  }, [])

  return null
}

export default Index
